import * as yup from "yup";

export const getValidationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t("formValidation.email"))
      .email(t("formValidation.invalidEmail")),
  });

export const initial = {
  email: "",
};
