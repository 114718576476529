import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import dynamic from "next/dynamic";

import useSettings from "@sholdi/hooks/useSettings";

import Link from "@sholdi/primitives/atoms/Link";
import Box from "@sholdi/primitives/atoms/Box";
import List from "../../molecules/List";
import ListItem from "../../molecules/List/ListItem";
import NewsletterSignUp from "../../molecules/NewsletterSignUp";
import Container from "../../molecules/Container";

const DefaultLogo = dynamic(() => import("./DefaultLogo"));
const DefaultLabel = dynamic(() => import("./DefaultLabel"));
const Cards = dynamic(() => import("../../molecules/Cards"));

const Footer = ({
  children,
  className,
  label: Label = DefaultLabel,
  logo: Logo = DefaultLogo,
  showCards = true,
  ...props
}) => {
  const { footerMenus = [] } = useSettings();

  return (
    <Box
      {...props}
      className={twMerge(
        "bg-primary-main px-4 md:px-24 pt-12 md:pt-16",
        className,
      )}
    >
      <Container className="flex flex-col gap-8 md:gap-16">
        <NewsletterSignUp isFooter />
        <div className="grid flex-1 grid-cols-2 xl:grid-cols-6">
          {(footerMenus || []).map((item) => (
            <List key={item.id} className="bg-transparent">
              <ListItem className="text-white font-light mb-4 p-0 cursor-default">
                {item.title}
              </ListItem>
              {(item.shopMenuItems ?? item.menuItems)?.map((listItem) => (
                <ListItem key={listItem.id} className="mb-3 p-0">
                  <Link
                    href={listItem.link}
                    className="text-white text-sm md:text-sm font-medium"
                  >
                    {listItem.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          ))}
        </div>
        {children}
      </Container>
      {showCards && (
        <Container>
          <Cards size="lg" showAll className="justify-end py-2" />
        </Container>
      )}
      <div className="flex bg-primary-main py-12 text-white">
        <Container className="flex justify-between items-center flex-col md:flex-row">
          <Link href="/" aria-label="logo" className="text-white">
            <Logo />
          </Link>

          <Label />
        </Container>
      </div>
    </Box>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
  ]),
  logo: PropTypes.elementType,
  showCards: PropTypes.bool,
};

Footer.displayName = "Footer";

export default Footer;
