import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Mail = ({ className, stroke, ...props }) => (
  <SVG aria-label="Mail" className={twMerge("w-3 h-3", className)} {...props}>
    <path
      d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Mail.propTypes = {
  className: PropTypes.string,
  stroke: PropTypes.string,
};
export default Mail;
