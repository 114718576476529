import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Box from "../Box";

const SVG = forwardRef(({ className = "", ...props }, ref) => (
  <Box
    as="svg"
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
    className={twMerge("w-3 h-3 fill-transparent", className)}
  />
));

SVG.propTypes = {
  adaptViewBox: PropTypes.bool,
  className: PropTypes.string,
};

SVG.displayName = "SVG";

export default memo(SVG);
