import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

export default function Times({ className, strokeWidth = "1.5", ...props }) {
  return (
    <SVG
      as="svg"
      viewBox="0 0 8 8"
      fill="none"
      className={twMerge("w-2 h-2", className)}
      {...props}
    >
      <path
        d="M7 1L1 7M1 1L7 7"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
}

Times.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  colorType: PropTypes.string,
  strokeWidth: PropTypes.string,
};
