import { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

import { getComponentProps } from "@sholdi/shared/helpers/getComponentProps";
import { omitMargin } from "@sholdi/shared/helpers/util";
import { omitPadding } from "@sholdi/shared/helpers/util";
import getVariantChildStyles from "@sholdi/shared/helpers/getVariantChildStyles";

import Input from "@sholdi/primitives/atoms/Input";

import {
  After,
  Before,
  ErrorIcon,
  InputWrapper,
  LabelBase,
  ErrorMessage,
  variants,
  WrapperBase,
} from "./components";

import { INPUT_PROPS } from "./utils";

const componentBase = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "input"),
  },
});

const FieldSet = forwardRef(
  (
    {
      as: Component,
      label,
      children,
      before,
      after,
      variant,
      error,
      showError,
      showErrorMessage,
      errorColor,
      ...props
    },
    ref,
  ) => {
    const { componentProps: _componentProps, ...fieldProps } = useMemo(
      () => getComponentProps(props, INPUT_PROPS),
      [props],
    );
    const { name, touched, disabled } = _componentProps;

    const showErrorStyle = touched && showError && error;

    const borderColorClass = useMemo(
      () => (showErrorStyle ? "border-error-main" : "border-gray-300"),
      [showErrorStyle],
    );

    const innerProps = omitPadding(omitMargin(fieldProps));

    // eslint-disable-next-line no-unused-vars
    const { touched: _touched, ...componentProps } = _componentProps;

    return (
      <WrapperBase
        variant={variant}
        {...fieldProps}
        className={twMerge("mb-2", fieldProps?.className ?? "")}
      >
        {label && (
          <LabelBase
            htmlFor={name}
            variant={variant}
            className="font-normal text-sm mb-3"
          >
            {label}
          </LabelBase>
        )}
        <InputWrapper
          variant={variant}
          {...innerProps}
          className={twMerge(
            "items-center text-gray-300 rounded",
            borderColorClass,
            disabled ? "bg-gray-50" : "bg-white",
            showErrorStyle
              ? "focus-within:border-error-main"
              : "focus-within:border-primary-main",
            !showErrorStyle
              ? "shadow-[0_0_0_4px_error-lightest]"
              : "shadow-[0_0_0_4px_primary-lightest]",
            //innerProps?.className,
          )}
        >
          {before && <Before before={before} variant={variant} />}
          <Component
            ref={ref}
            id={name}
            {...innerProps}
            {...componentProps}
            variant={variant}
            className={twMerge(
              "bg-transparent block w-full border-0 appearance-none placeholder:font-normal placeholder:text-gray-300 text-primary-text focus:outline-none focus:border-none",
              componentBase({ variant }),
            )}
          >
            {children}
          </Component>

          {showErrorStyle && touched && (
            <ErrorIcon
              isSelect={Component.displayName === "Select"}
              variant={variant}
              after={after}
              className={errorColor}
            />
          )}
          {after && (
            <After
              isSelect={Component.displayName === "Select"}
              hasError={!!error}
              after={after}
              variant={variant}
            />
          )}
        </InputWrapper>
        {showError && showErrorMessage && error && touched && (
          <ErrorMessage
            error={error}
            variant={variant}
            className={errorColor}
          />
        )}
      </WrapperBase>
    );
  },
);

FieldSet.displayName = "FieldSet";

FieldSet.defaultProps = {
  as: Input,
  variant: "none",
  showError: true,
  showErrorMessage: true,
  errorColor: "text-error-main",
};

FieldSet.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  before: PropTypes.node,
  after: PropTypes.node,
  showError: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  errorColor: PropTypes.string,
  variant: PropTypes.oneOf([
    "none",
    "default",
    "newsletter",
    "status",
    "collection",
    "messenger",
    "filterBar",
    "rangeSlider",
    "colorPicker",
  ]),
  children: PropTypes.node,
};

export default FieldSet;
