import { push } from "./index";

export const onCategoryViewPush = (category) => {
  const isSimplifedProductData = "name" in category;

  push([
    "setEcommerceView",
    false,
    false,
    isSimplifedProductData
      ? category.name
      : category.categoryDescriptions[0].name,
  ]);
  push(["trackPageView"]);
};

export const onProductViewPush = (product) => {
  const isSimplifedProductData = "name" in product;

  push([
    "setEcommerceView",
    isSimplifedProductData ? product.id : product.productCombination?.id,
    isSimplifedProductData ? product.name : product.productDescriptions[0].name,
    isSimplifedProductData
      ? product.categoryName
      : product.category?.categoryDescriptions[0].name,
    isSimplifedProductData ? product.price : product.productCombination?.price,
    1,
  ]);
  push(["trackPageView"]);
};

export const onAddProductToCart = (product, combination) => {
  const { id, salePrice, price } = combination;
  const { productDescriptions, category } = product;
  push([
    "addEcommerceItem",
    id,
    product.name ?? productDescriptions[0].name,
    product.categoryName ?? category.categoryDescriptions[0].name,
    (salePrice && salePrice !== "0" ? salePrice : price) / 100,
    1,
  ]);
};

export const onCartTotalChange = (total) => {
  push(["trackEcommerceCartUpdate", total / 100]);
};

export const onRemoveCartItem = (combination) =>
  push(["removeEcommerceItem", combination.id]);

export const onCreateOrder = (order) =>
  push([
    "trackEcommerceOrder",
    order.id,
    order.subtotal,
    order.subtotal,
    order.taxRate,
    order.shippingTotal,
    order.discountTotal,
  ]);

export const onRegisterCompleted = () =>
  push([
    "trackEvent",
    "Registration",
    "A user registered",
    "registerCompleted",
  ]);

export const onCreateProductReview = () =>
  push(["trackEvent", "Reviews", "add review", "productReview", 1]);

export const onNewsletterSignup = () =>
  push(["trackEvent", "Newsletter", "Newsletter signup", "newsLetterSignup"]);
