import memoize from 'lodash.memoize'
export const getProps = memoize(test => props => {
  const next = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in props) {
    if (test(key || '')) next[key] = props[key]
  }
  return next
})

const W = /(min|max\.)?(?:width|w)/gi
const B = /^borderColor?$/
const P = /^(padding|p)[trblxy]?$/
const flex = /^flex/i
const MRE = /^m[trblxy]?$/
const CHILDREN = /^children?$/

export const getMargin = getProps(memoize(k => MRE.test(k)))
export const getBorderColor = getProps(k => B.test(k))
export const omitMargin = getProps(memoize(k => !MRE.test(k)))
export const omitWidth = getProps(k => !W.test(k))
export const getWidth = getProps(memoize(k => W.test(k)))
export const omitPadding = getProps(k => !P.test(k))
export const getPadding = getProps(k => P.test(k))
export const getFlex = getProps(k => flex.test(k))
export const omitFlex = getProps(k => !flex.test(k))
export const omitChildren = getProps(k => !CHILDREN.test(k))
export const isServer = typeof window === 'undefined'
