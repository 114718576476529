import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Text from "@sholdi/primitives/atoms/Box";

const Actions = ({ actions }) => (
  <>
    {actions && (
      <div className="flex mt-2.5">
        {actions.map((action, index) => (
          <Text
            key={actions.name}
            onClick={() => action.action}
            className={twMerge(
              "cursor-pointer font-heading font-medium text-sm leading-5",
              index !== 0 ? "ml-3" : "ml-0",
              action.type === "primary" ? "text-primary-dark" : "black",
            )}
          >
            {action.name}
          </Text>
        ))}
      </div>
    )}
  </>
);

Actions.displayName = "Actions";

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Actions;
