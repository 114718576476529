import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Box from "../Box";
import Flex from "../Flex";
import SVG from "../SVG";

import "./Spinner.module.css";

const Spinner = ({
  gap,
  thickness,
  // speed,
  overlay,
  isDotLoader,
  className = "",
  overlayClassName = "",
  ...props
}) => {
  const spinner = isDotLoader ? (
    <Box className={twMerge(`w-4 h-4 rounded-full sk-circle`, className)}>
      {Array(12)
        .fill(0)
        .map((_, i) => (
          <Box
            className={`sk-circle${i + 1} sk-childClass before:bg-primary-main`}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
    </Box>
  ) : (
    <SVG
      className={twMerge(`w-4 h-4 animate-spin`, className)}
      {...props}
      role="img"
      aria-labelledby="title desc"
      viewBox="0 0 32 32"
    >
      <title id="title">Circle loading spinner</title>
      <desc id="desc">
        Image of a partial circle indicating &qoute;loading&qoute;.
      </desc>
      <circle
        role="presentation"
        cx={16}
        cy={16}
        r={14 - thickness / 2}
        stroke="currentColor"
        fill="none"
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap="round"
      />
    </SVG>
  );

  return overlay ? (
    <Flex
      className={twMerge(
        "z-[300] fixed w-screen h-screen bg-[rgba(255,255,255,0.8)] justify-center items-center",
        overlayClassName,
      )}
    >
      {spinner}
    </Flex>
  ) : (
    spinner
  );
};

Spinner.defaultProps = {
  thickness: 3,
  gap: 4,
  speed: "fast",
  overlay: false,
};

Spinner.propTypes = {
  thickness: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  gap: PropTypes.oneOf([1, 2, 3, 4, 5]),
  speed: PropTypes.oneOf(["fast", "slow"]),
  size: PropTypes.string,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  overlay: PropTypes.bool,
  isDotLoader: PropTypes.bool,
};

Spinner.displayName = "Spinner";

export default Spinner;
