import { useState } from "react";

import { getToast } from "@sholdi/toast";

import useTranslation from "./useTranslation";

import { onNewsletterSignup } from "@sholdi/shared/lib/matomo/utils";

export const useRegisterToNewsletter = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const register = async (values) => {
    setLoading(true);
    try {
      const response = await fetch("/api/newsletter/subscribe", {
        method: "POST",
        body: JSON.stringify(values),
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });

      setLoading(false);

      if (response.ok) {
        onNewsletterSignup();
        getToast("success", t("successfulNewsLetterSignUp"));
      }

      if (response.status === 422) {
        getToast("error", t("alreadyInNewsLetter"));
      }
    } catch (err) {
      setLoading(false);
      getToast("error", t("somethingWentWrong"));
    }
  };

  return { loading, register };
};

export default useRegisterToNewsletter;
