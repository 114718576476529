import PropTypes from "prop-types";

import SVG from "./SVG";
import { twMerge } from "tailwind-merge";

const Check = ({ className, strokeWidth = 2, ...props }) => (
  <SVG aria-label="Check" className={twMerge("w-3 h-3", className)} {...props}>
    <path
      d="M20 6L9 17L4 12"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Check.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Check;
