import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const TimesNot = ({ className, ...props }) => (
  <SVG
    viewBox="0 0 12 12"
    aria-label="TimesNot"
    fill="transparent"
    className={twMerge("w-3 h-3", className)}
    {...props}
  >
    <path
      d="M11 1L1 11M1 1L11 11"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

TimesNot.propTypes = {
  className: PropTypes.string,
};

export default TimesNot;
