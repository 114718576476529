import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const WRAPPER_BG_MAP = {
  primary: "bg-primary-lightest",
  error: "bg-error-lightest",
  warning: "bg-warning-lightest",
  success: "bg-success-lightest",
  gray: "bg-gray-0",
};

const INNER_CIRCLE_BG_MAP = {
  primary: "bg-primary-lighter",
  error: "bg-error-lighter",
  warning: "bg-warning-lighter",
  success: "bg-success-lighter",
  gray: "bg-gray-100",
};

const CONTENT_COLOR_MAP = {
  primary: "text-primary-main border-primary-main",
  error: "text-error-main border-error-main",
  warning: "text-warning-main border-warning-main",
  success: "text-success-main border-success-main",
  gray: "text-gray-500 border-gray-500",
};

const CircleIcon = ({
  color = "primary",
  icon: Icon,
  isOutline,
  variant,
  iconProps = {},
  className,
  innerCircleClassName,
  ...props
}) => (
  <div
    className={twMerge(
      "flex items-center justify-center flex-none rounded-full",
      WRAPPER_BG_MAP[color],
      isOutline ? "bg-transparent" : "",
      className,
    )}
    {...props}
  >
    <div
      className={twMerge(
        "flex items-center justify-center flex-none rounded-full",
        variant === "whiteRed" ? "border-white text-error-main" : "",
        CONTENT_COLOR_MAP[color],
        INNER_CIRCLE_BG_MAP[color],
        isOutline ? "border bg-transparent" : "border-0",
        innerCircleClassName,
      )}
    >
      {Icon && (
        <Icon
          strokeWidth="2"
          className={twMerge("size-3", iconProps?.className)}
          {...iconProps}
        />
      )}
    </div>
  </div>
);

CircleIcon.displayName = "CircleIcon";

CircleIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  color: PropTypes.string,
  isOutline: PropTypes.bool,
  variant: PropTypes.oneOf(["redWhite"]),
  iconProps: PropTypes.shape({}),
  className: PropTypes.string,
  innerCircleClassName: PropTypes.string,
};

export default CircleIcon;
