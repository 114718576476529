import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { twMerge } from "tailwind-merge";

import Close from "../Close";
import Flex from "../Flex";

import { cva } from "class-variance-authority";

const alert = cva(
  "relative items-center font-body font-medium leading-tight tracking-wide rounded p-4",
  {
    variants: {
      type: {
        primary:
          "text-primary-main bg-primary-lightest border border-primary-light",
        success:
          "text-success-main bg-success-lightest border border-success-light",
        error: "text-error-main bg-error-lightest border border-error-light",
        warning:
          "text-warning-dark bg-warning-lightest border border-warning-main",
      },
    },
    defaultVariants: {
      type: "primary",
    },
  },
);

const Alert = ({
  timeout = 3000,
  dismiss,
  children,
  variant = "primary",
  className = "",
  dismissible,
}) => {
  const [showAlert, setShowAlert] = useState(true);
  useEffect(() => {
    if (dismiss) {
      setTimeout(() => {
        setShowAlert(false);
      }, timeout);
    }
  }, [dismiss, timeout]);

  if (showAlert) {
    return (
      <Flex className={twMerge(alert({ type: variant }), className)}>
        {dismissible && (
          <Close onClick={() => setShowAlert(false)} className="text-current" />
        )}
        {children}
      </Flex>
    );
  }
  return null;
};

Alert.propTypes = {
  variant: PropTypes.oneOf(["primary", "info", "warning", "success", "error"]),
  timeout: PropTypes.number,
  dismiss: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  dismissible: PropTypes.bool,
};

Alert.displayName = "Alert";

export default Alert;
